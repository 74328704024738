export enum NETWORK {
  ETHEREUM = 1,
  ETHEREUM_SEPOLIA = 11155111,
  POLYGON = 137,
  POLYGON_AMOY = 80002,
  AVALANCHE = 43114,
  AVALANCHE_FUJI = 43113,
  BASE = 8453,
  BASE_SEPOLIA = 84532,
  LAMINA1 = 10849,
  LAMINA1_FUJI = 764984,
}

export const NETWORK_NAME_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: 'Ethereum',
  [NETWORK.ETHEREUM_SEPOLIA]: 'Ethereum Testnet',
  [NETWORK.POLYGON]: 'Polygon',
  [NETWORK.POLYGON_AMOY]: 'Polygon Testnet',
  [NETWORK.AVALANCHE]: 'Avalanche',
  [NETWORK.AVALANCHE_FUJI]: 'Avalanche Testnet',
  [NETWORK.BASE]: 'Base',
  [NETWORK.BASE_SEPOLIA]: 'Base Testnet',
  [NETWORK.LAMINA1]: 'Lamina1',
  [NETWORK.LAMINA1_FUJI]: 'Lamina1 Testnet',
}

export const generateAddressLink = (chainId: NETWORK, address: string) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/address/${address}`;
};

export const generateTxLink = (chainId: NETWORK, transactionHash: string) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/tx/${transactionHash}`;
};

export const TX_BASE_URL_FOR_CHAIN_ID = {
  [NETWORK.ETHEREUM]: "etherscan.io",
  [NETWORK.ETHEREUM_SEPOLIA]: "sepolia.etherscan.io",
  [NETWORK.POLYGON]: "polygonscan.com",
  [NETWORK.POLYGON_AMOY]: "amoy.polygonscan.com",
  [NETWORK.AVALANCHE]: "snowscan.xyz",
  [NETWORK.AVALANCHE_FUJI]: "testnet.snowscan.xyz",
  [NETWORK.BASE]: "basescan.org",
  [NETWORK.BASE_SEPOLIA]: "sepolia.basescan.org",
  [NETWORK.LAMINA1]: "subnets.avax.network/lamina1",
  [NETWORK.LAMINA1_FUJI]: "subnets-test.avax.network/lamina1tes",
};

export const RPC_URL_BY_CHAIN_ID = {
  [NETWORK.AVALANCHE]: "https://api.avax.network/ext/bc/C/rpc",
  [NETWORK.AVALANCHE_FUJI]: "https://api.avax-test.network/ext/bc/C/rpc",
  [NETWORK.ETHEREUM]: "https://rpc.ankr.com/eth/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.ETHEREUM_SEPOLIA]: "https://rpc.ankr.com/eth_sepolia/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.POLYGON]: "https://rpc.ankr.com/polygon/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.POLYGON_AMOY]: "https://rpc.ankr.com/polygon_amoy/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.BASE]: 'https://rpc.ankr.com/base/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa',
  [NETWORK.BASE_SEPOLIA]:
    'https://rpc.ankr.com/base_sepolia/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa',
  [NETWORK.LAMINA1]: "https://subnets.avax.network/lamina1/mainnet/rpc",
  [NETWORK.LAMINA1_FUJI]: "https://subnets.avax.network/lamina1tes/testnet/rpc",
}

export const TESTNETS = [
  NETWORK.ETHEREUM_SEPOLIA,
  NETWORK.POLYGON_AMOY,
  NETWORK.AVALANCHE_FUJI,
  NETWORK.BASE_SEPOLIA,
  NETWORK.LAMINA1_FUJI,
];

export const isTestnet = (chainId: NETWORK) => TESTNETS.includes(chainId);
